import ReactVisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import clsx from 'clsx';
import {
  BenefitWrapper,
  SectionWrapper,
  Title,
  Description,
  BenefitImage,
} from './styled';
import { benefits } from '../constants';

function Benefit() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <SectionWrapper>
        {benefits.map((benefit) => (
          <BenefitWrapper key={benefit.title}>
            <BenefitImage
              className={clsx('fadeInTopX', viewed && 'fadeInTop')}
              src={benefit.image}
              alt={benefit.title}
            />
            <Title>{benefit.title}</Title>
            <Description>{benefit.description}</Description>
          </BenefitWrapper>
        ))}
      </SectionWrapper>
    </ReactVisibilitySensor>
  );
}

export default Benefit;
